@import url('https://fonts.googleapis.com/css?family=Roboto+Slab&display=swap');
.new-blog {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #E8F0CE;
    border-radius: 25px;
    width: 80%;
    max-width: 80%;
    margin: 1em;
    padding: 1em;
}

.new-blog-header {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    width: 250px;
}

.blog-form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.blog-form button {
    padding: 0.25em 0.75em;
    text-decoration: none;
    background-color: #F0D6CE;
    color: inherit;
    border-radius: 25px;
    font-family: inherit;
    border: none;
    font-size: 15px;
}

.blog-form button:hover {
    color: #fff;
    text-shadow: 1px 1px 1px #000;
    background-color: #CEE8F0;
}