@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Noto+Sans:ital,wght@0,100..900;1,100..900&family=Roboto+Slab:wght@100..900&display=swap");

html {
  font-family: "Roboto Slab", Arial, Helvetica, sans-serif;
  min-height: 100%;
  height: 100%;
}

body {
  margin: 0px;
  min-height: 100%;
  height: 100%;
}

.nav-sticky + main {
  padding-top: 60px;
}

#root {
  display: flex;
  min-height: 100%;
  height: 100%;
  flex-direction: column;
}

main {
  width: auto;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  min-height: 100%;
  height: 100%;
  text-align: center;
  justify-content: flex-start;
  align-items: center;
}

@media (max-width: 768px) {
  main {
    font-size: 18px;
    padding-top: 60px;
  }
}
