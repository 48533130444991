.nav-links {
  font-size: 15px;
  list-style: none;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 0;
  margin: 5px;
}

.nav-links li {
  padding: 0.25em 0.25em;
}

.nav-links-item {
  white-space: nowrap;
  text-align: center;
  margin: 0;
  padding: 5px 7px;
  border-radius: 25px;
}

.nav-links-item:hover {
  background-color: #f0d6ce;
}

.nav-links-item__active {
  white-space: nowrap;
  text-align: center;
  background-color: #f0d6ce;
  margin: 0;
  padding: 5px 7px;
  border-radius: 25px;
}

.nav-links a {
  text-align: center;
  color: #000;
  text-decoration: none;
}

.nav-sticky {
  margin-top: -1em;
  margin: -5px -5px -5px 0px;
  min-height: 3em;
  position: fixed;
  top: 0;
  width: 100%;
  background-color: #cee8f0;
}

@media (max-width: 768px) {
  .nav-links {
    font-size: 20px;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 100;
    height: 100vh;
    width: 70%;
    background-color: #fff;
    flex-direction: column;
    margin: 0;
  }
}
