.comment {
    background-color: #D6E4A7;
    border-radius: 25px;
    margin: 1em 0em;
    padding: 1em 1em;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.comment-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.comment p {
    text-align: left;
}

.comment-form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.comment-form__header {
    width: 250px;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    align-self: center;
}
