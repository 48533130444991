.your-stories {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.your-stories-blogs {
    max-width: 1080px;
    width: 75%;
}

@media (max-width: 768px) {
    .your-stories-blogs {
        width: 100%;
    }
}