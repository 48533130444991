.auth-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 100%;
    justify-content: center;
    align-items: center;
}

.login-form {
    display: flex;
    padding: 1em 0em;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    width: 28em;
    background-color: #E8F0CE;
    border-radius: 20px;
}

.login-form__inputs {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
}

.password-rules {
    font-size: 15px;
    margin: 5px 0px;
}

.password-rules-list {
    display: flex;
    flex-direction: column;
    font-size: 13px;
    justify-content: flex-start;
    align-items: flex-start;
}

@media (max-width: 768px) {
    .login-form {
        width: 90%;
    }
}