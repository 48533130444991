@import url('https://fonts.googleapis.com/css?family=Roboto+Slab&display=swap');
.new-comment-form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.new-comment-buttons {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    width: 250px;
}
