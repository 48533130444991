.confirmation-modal {
    display: flex;
    flex-direction: column;
    border-radius: 20px;
    background-color: #fff;
    padding: 1rem;
    justify-content: center;
    align-items: center;
}

.confirmation-modal__buttons {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    width: 250px;
}

@media (max-width: 768px) {
    .confirmation-modal {
        width: 90%;
        font-size: 18px;
    }
}