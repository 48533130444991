.download-button {
  font-family: inherit;
  font-size: inherit;
  display: flex;
  color: #000;
  height: 30px;
  width: 110px;
  text-align: center;
  text-decoration: none;
  background-color: #f0d6ce;
  border-radius: 25px;
  justify-content: center;
  align-items: center;
  padding: 0;
  margin: 0.5em;
  border: 1px solid transparent;
}

.download-button:hover {
  color: #fff;
  text-shadow: 1px 1px 2px #000;
}

.reverify-button {
  margin: 0;
  padding: 0;
  font-family: inherit;
  font-size: inherit;
  color: #5e74f0;
  border: 1px solid transparent;
  background-color: #fff;
  text-decoration-line: underline;
}

.reverify-button:hover {
  color: #fff;
  background-color: #f0d6ce;
  border-radius: 10px;
}

.link-button {
  margin: 1em;
  padding: 0.25em 0.75em;
  text-decoration: none;
  background-color: #f0d6ce;
  color: inherit;
  border-radius: 25px;
  font-family: inherit;
  border: none;
  font-size: 15px;
}

.link-button:hover {
  color: #fff;
  text-shadow: 1px 1px 1px #000;
  background-color: #cee8f0;
}

.button {
  padding: 0.25em 0.75em;
  text-decoration: none;
  background-color: #f0d6ce;
  color: inherit;
  border-radius: 25px;
  font-family: inherit;
  border: none;
  font-size: 15px;
}

.button:hover {
  color: #fff;
  text-shadow: 1px 1px 1px #000;
  background-color: #cee8f0;
}

.button--danger {
  padding: 0.25em 0.75em;
  text-decoration: none;
  background-color: #f99;
  color: inherit;
  border-radius: 25px;
  font-family: inherit;
  border: none;
  font-size: 15px;
}

.button--danger:hover,
.button--submit:hover {
  color: #fff;
  text-shadow: 1px 1px 1px #000;
}

.button--submit {
  padding: 0.25em 0.75em;
  text-decoration: none;
  background-color: #cee8f0;
  color: inherit;
  border-radius: 25px;
  font-family: inherit;
  border: none;
  font-size: 15px;
}

.button--reset-password {
  margin-top: 5px;
}
