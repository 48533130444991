.blog-delete-button {
    padding: 0.25em 0.75em;
    text-decoration: none;
    background-color: #F99;
    color: inherit;
    border-radius: 25px;
    font-family: inherit;
    border: none;
    font-size: 15px;
    margin: 0.5em;
}

.blog-delete-button:hover {
    color: #fff;
    text-shadow: 1px 1px 1px #000;
    background-color: #CEE8F0;
}