.home-item {
    margin: 1em;
}

.home-item__link {
    padding: 0.25em 0.75em;
    text-decoration: none;
    background-color: #F0D6CE;
    color: inherit;
    border-radius: 25px;
    font-family: inherit;
    border: none;
    font-size: 15px;
}

.home-item__link:hover {
    color: #fff;
    text-shadow: 1px 1px 1px #000;
    background-color: #CEE8F0;
}